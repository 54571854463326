<template>
	<div class="forumdet">
		<tabBar :cardIndex="1" :activeIndexsa='1'></tabBar>
		<!-- <div style="height: 85px">
		  <div class="headers">
		    <div class="headss">
					<div v-for="(item, index) in list" :key="index" class="tips" :class="filg == index ? 'active_tip' : ''"
					  @click="qies(index)">
						<img v-if="filg==index" :src='item.imgs' />
					  <img v-else :src='item.img' />
						<div>{{ item.name }}</div>
					</div>
				</div>
		  </div>
		</div> -->
		<div class="content">
			<div style="background-color: #fff;">
				<div class="cont_left">
					<div class="contforum">
						<div class="conthed">
							<img :src='contents.headImg' v-if="contents.headImg" />
							<img src='@/assets/img/user.png' v-else />
							<span>{{contents.nickName}}</span>
						</div>
						<div class="contentxx">{{contents.content}}</div>
						<div class="cont_imgs" v-if="contents.contentImg">
							<img v-for="(item,index) in contents.contentImg" :key='index' :src='item' />
						</div>
						<div class="cont_imgs" v-if="contents.remarks">
							<video :src='contents.remarks' style="width: 80%;" controls></video>
						</div>
						<div class="cont_boot">
							<div>{{contents.createTime}}</div>
							<div class="cont_boots">
								<div style="margin-right: 32px;cursor: pointer;" @click="report(contents)">举报</div>
								<div class="cont_pinl" style="margin-right: 29px;" @click="share">
									<img style="height: 19px;width: 19px;" src="../../assets/img/xie/fenxiang.png" />
									<span>分享</span>
								</div>
								<div class="cont_pinl" style="margin-right: 29px;cursor: default;">
									<img style="height: 17px;width: 22px;" src="../../assets/img/xie/liulan.png" />
									<span>{{contents.viewNum}}</span>
								</div>
								<div class="cont_pinl" style="margin-right: 29px;" @click="reply(contents)">
									<img style="height: 18px;width: 19px;" src="../../assets/img/xie/pinglun.png" />
									<span>{{contents.replyNum}}</span>
								</div>
								<div class="cont_pinl" @click="comment(contents.id,!contents.isDoUp)">
									<img style="height: 18px;width: 18px;" v-if="!contents.isDoUp" src="../../assets/img/xie/zan.png" />
									<img style="height: 18px;width: 18px;" v-else src="../../assets/img/4509.png" />
									<span :style="contents.isDoUp?'color:#f26034':'' ">{{contents.giveNum}}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="comment">
					<div style="padding: 0 25px;">全部评论 {{contents.replyNum}}</div>
					<div v-for="(item,index) in pinl" 
					 v-show="index<num" 
					 :key='index' style="padding: 0 25px;margin-top: 22px;">
						<div class="comment_img">
							<img :src='item.headImg' v-if="item.headImg" />
							<img src='@/assets/img/user.png' v-else />
							<span>{{item.nickName}}</span>
						</div>
						<div class="comment_cont">
							<div>{{item.content}}</div>
							<div class="rep_time">
								<span>{{item.createTime}}</span>
								<div class="rep_huif" @click="replys(item)">
									<img src="../../assets/img/xie/huifu.png" />
									<span>回复</span>
								</div>
							</div>
							<div class="coment_pinl" v-if="item.forumReplieChild.length>0">
								<div v-for="(ites,ind) in item.forumReplieChild" :key='ind'
								 v-show="ind<nums"
								 class="coment_pinl_a" :class="ind==item.forumReplieChild.length-1||ind==nums-1?'activ_coms':''">
									<div class="coment_pinl_b">
										<img :src='ites.headImg' v-if="ites.headImg" />
										<img src='@/assets/img/user.png' v-else />
										<span v-if="ites.nickNameTwo">
											<span>{{ites.nickName}}</span>
											<span> 回复 </span>
											<span>{{ites.nickNameTwo}}</span>
										</span>
										<span v-else>{{ites.nickName}}</span>
									</div>
									<div class="commen_pinl_c">
										<div style="margin-bottom: 13px;">{{ites.content}}</div>
										<div class="rep_times">
											<span>{{ites.createTime}}</span>
											<div class="rep_huif" @click="replys(ites)">
												<img src="../../assets/img/xie/huifu.png" />
												<span>回复</span>
											</div>
										</div>
									</div>
								</div>
								<div class="commen_pinl_d"
								 v-if="item.forumReplieChild.length>2" @click="opensa(item.forumReplieChild)">{{textsa}}</div>
							</div>
						</div>
					</div>
					<div class="open" v-if="pinl.length>2" @click="opens">{{texts}}</div>
					<div class="publish">
						<div @click="reply(contents)">请发表你的评论</div>
						<el-button type="primary" size="small" @click='reply(contents)'>发布</el-button>
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="heads">
			<i class="el-icon-arrow-up" style="font-size: 30px;color: #ACACAC;"></i>
		</div> -->
		<div class="dilogs">
			<el-dialog title="举报" :visible.sync="dialogVisible" width="30%">
			  <div>
					<div class="dilog_a">
						<div class="dils_labe">举报原因：</div>
						<el-select v-model="value" placeholder="请选择" style="width: 400px;" @change="selects">
							<el-option value="不好用"></el-option>
							<el-option value="不好用s"></el-option>
							<el-option value="其他"></el-option>
						</el-select>
					</div>
					<div class="dilog_a" v-if="inputsa">
						<div class="dils_labe">其他原因：</div>
						<el-input v-model="input" placeholder="请输入内容" type='textarea' :maxlength='500' :rows="4" show-word-limit style="width: 400px;"></el-input>
					</div>
				</div>
			  <span slot="footer" class="dialog-footer">
			    <el-button @click="dialogVisible = false">取 消</el-button>
			    <el-button type="primary" @click="reporda">确 定</el-button>
			  </span>
			</el-dialog>
		</div>
		<div class="dilogsa">
			<el-dialog :title="titles" :visible.sync="dialogVisibles" width="500px">
			  <div>
					<el-input v-model="comcont.content" type='textarea' maxlength="500" :rows='5' show-word-limit placeholder="请输入内容"></el-input>
				</div>
			  <span slot="footer" class="dialog-footer">
			    <el-button @click="dialogVisibles = false">取消</el-button>
			    <el-button type="primary" @click="commentsa">确认</el-button>
			  </span>
			</el-dialog>
		</div>
		<footers></footers>
	</div>
</template>

<script>
	import tabBar from "@/components/tabBar.vue";
	import footers from "@/components/footer.vue";
	import { getforumdetail,putaddUp,postforumReply,forumread} from "@/request/homeApi";
	import {reportforum } from "@/request/xie";
	export default{
		components:{
			tabBar,
			footers
		},
		data(){
			return{
				forumid:'',
				filg:1,
				list: [
				  { name: "博客",img:require('../../assets/img/xie/boke.png'),imgs:require('../../assets/img/xie/boke2.png')},
				  { name: "论坛",img:require('../../assets/img/xie/luntan2.png'),imgs:require('../../assets/img/xie/luntan.png')},
				],
				contents:{},
				dialogVisible:false,
				value:'',
				input:'',
				inputsa:false,
				pinl:[],
				dialogVisibles:false,
				inputs:'',
				tokens:'',
				repordata:{
					reasonName:'',
					forumId:''
				},
				titles:'',
				comcont:{
					forumId: '',
					content: '',
				},
				pingid:'',
				num:2,
				texts:'展开全部评论',
				nums:2,
				textsa:'展开全部回复',
				arrList:[]
			}
		},
		mounted() {
			if(this.$route.query.id){
				this.forumid=this.$route.query.id
				this.getforumdets()
			}
			if(localStorage.getItem('token')){
				this.tokens=localStorage.getItem('token')
			}
			this.browse()
		},
		methods:{
			//增加浏览数量
			async browse(){
				let res=await forumread(this.forumid)
			},
			//获取论坛详情
			async getforumdets(){
				let res=await getforumdetail(this.forumid)
				//console.log(res)
				this.contents=res.result
				if(this.contents.contentImg){
					let sk=this.contents.contentImg.split(',')
					this.contents.contentImg=sk
				}else{
					this.contents.contentImg=null
				}
				this.pinl=res.result.forumReplies
				this.pinl.forEach((item,index)=>{
					this.arrList=[]
					if(item.forumReplieChild.length>0){
						this.arrListpush(item.forumReplieChild);
						item.forumReplieChild=this.arrList
						this.arrlists(item.forumReplieChild)
					}
				})
				//console.log(this.pinl)
			},
			//处理谁回复了谁数据
			arrlists(arrlist){
				arrlist.forEach((ites,ind)=>{
					arrlist.forEach((itesa,inds)=>{
						if(ites.id==itesa.pid){
							itesa.nickNameTwo=ites.nickName
						}
					})
				})
			},
			//递归出来回复数据
			arrListpush(arrays){
				arrays.forEach((item) => {
				   this.arrList.push(item);
				  if (item.forumReplieChild.length > 0) {
				    this.arrListpush(item.forumReplieChild);
				  }
				});
			},
			//切换
			qies(ind) {
			  if(ind == 0){
			    this.$router.push({path:"compare"})
			  }else{
					this.$router.push({path:"forumlist"})
				}
			  this.$nextTick(() => {
			    this.filg = ind;
			  });
			},
			//打开举报弹框
			report(item){
				if(!this.tokens){
					this.$message.error('请登录之后再操作')
					return false
				}
				this.value=''
				this.input=''
				this.repordata.forumId=item.id
				this.inputsa=false
				this.dialogVisible=true
			},
			//举报原因
			selects(val){
				if(val=='其他'){
					this.inputsa=true
				}else{
					this.inputsa=false
				}
			},
			//举报
			reporda(){
				if(!this.inputsa&&this.value==''){
					this.$message.error('请选择举报原因')
					return false
				}
				if(this.inputsa&&this.input==''){
					this.$message.error('请填写举报原因')
					return false
				}
				if(this.inputsa){
					this.repordata.reasonName=this.input
				}else{
					this.repordata.reasonName=this.value
				}
				reportforum(this.repordata).then((res)=>{
					if(res.code==200){
						this.$message.success('举报成功')
					}
					this.dialogVisible=false
				})
			},
			//评论
			reply(item){
				if(!this.tokens){
					this.$message.error('请登录之后再操作')
					return false
				}
				this.titles='评论'
				this.comcont.content=''
				this.pingid=''
				this.comcont.forumId=item.id
				this.dialogVisibles=true
			},
			//回复
			replys(item){
				if(!this.tokens){
					this.$message.error('请登录之后再操作')
					return false
				}
				this.pingid = item.id;
				this.comcont.content=''
				this.comcont.forumId=item.forumId
				this.titles=`回复 ${item.nickName} 的评论`
				this.dialogVisibles=true
			},
			//分享
			share(){
				if(!this.tokens){
					this.$message.error('请登录之后再操作')
					return false
				}
				this.$copyText(window.location.href).then(
					(res) => {
						this.$message.success('复制成功');
					}, (error) => {
						this.$message.success('复制失败');
					}
				);
			},
			//点赞
			comment(id,isfor){
				if(!this.tokens){
					this.$message.error('请登录之后再操作')
					return false
				}
				putaddUp(id,isfor).then((res)=>{
					if (res.code == 200) {
					  if(isfor){
					    this.contents.isDoUp = true;
					    this.contents.giveNum = Number(this.contents.giveNum) + 1;
					  }else{
					    this.contents.isDoUp = false;
					    this.contents.giveNum = Number(this.contents.giveNum) - 1;
					  }
					  this.$message.success("操作成功！");
					}
				})
			},
			//评论
			commentsa(){
				if(this.comcont.content==''){
					this.$message.error('请填写评论内容')
					return false
				}
				let data={}
				if(this.pingid){
					data = {
					  pid: this.pingid,
					  content: this.comcont.content,
						forumId:this.comcont.forumId
					};
				}else{
					data=this.comcont
				}
				postforumReply(data).then((res)=>{
					if (res.code == 200) {
					  this.dialogVisibles=false
						this.getforumdets()
					}
				})
			},
			//展开全部评论
			opens(){
				if(this.num==2){
					this.texts='收起评论'
					this.num=this.pinl.length+1
				}else{
					this.texts='展开全部评论'
					this.num=2
				}
			},
			//展开全部回复
			opensa(ints){
				if(this.nums==2){
					this.textsa='收起评论'
					this.nums=ints.length+1
				}else{
					this.textsa='展开全部评论'
					this.nums=2
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.forumdet{
		.headers {
		  width: 100%;
		  height: 85px;
		  background-color: #ededed;
		  color: #535353;
		  font-size: 16px;
		  display: flex;
		  flex-flow: row;
		  justify-content: center;
		  align-items: center;
		  position: fixed;
		  z-index: 99;
		  left: 0;
		  top: 80px;
			.headss{
				margin-left: 80px;
				height: 100%;
				display: flex;
				flex-flow: row;
				justify-content: center;
				align-items: center;
			}
		  .tips {
		    margin-left: 95px;
		    cursor: pointer;
				display: flex;
				flex-flow: column;
				justify-content: center;
				img{
					width: 33px;
					height: 31px;
				}
		  }
		  .active_tip {
		    color: #00a3e0;
		  }
		}
		.content{
			display: flex;
			flex-flow: row;
			justify-content: center;
			background-color: #F7F7F7;
			padding-top: 120px;
			padding-bottom: 39px;
			box-sizing: border-box;
			.cont_left{
				width: 690px;
				background-color: #fff;
				padding: 0 25px 32px 25px;
				box-sizing: border-box;
				.contforum{
					margin-top: 26px;
					.conthed{
						display: flex;
						align-items: center;
						font-size: 16px;
						color: #535353;
						img{
							width: 40px;
							height: 40px;
							border-radius: 50%;
							margin-right: 11px;
						}
					}
					.contentxx{
						color: #535353;
						font-size: 18px;
						margin-top: 24px;
						margin-bottom: 20px;
					}
					.cont_imgs{
						display: flex;
						flex-flow: column;
						justify-content: center;
						margin-bottom: 20px;
						img{
							width: 100%;
							margin-bottom: 10px;
						}
					}
					.cont_boot{
						display: flex;
						flex-flow: row;
						justify-content: space-between;
						align-items: center;
						color: #999999;
						font-size: 14px;
					}
					.cont_boots{
						display: flex;
						font-size: 16px;
						align-items: center;
						.cont_pinl{
							display: flex;
							align-items: center;
							cursor: pointer;
							img{
								margin-right: 6px;
							}
						}
					}
				}
			}
			.comment{
				width: 690px;
				background-color: #fff;
				padding: 20px 0 10px 0;
				box-sizing: border-box;
				color: #535353;
				font-size: 16px;
				.comment_img{
					img{
						width: 36px;
						height: 36px;
						border-radius: 50%;
						margin-right: 9px;
					}
				}
				.comment_cont{
					margin-top: 7px;
					margin-left: 45px;
				}
				.rep_time{
					display: flex;
					align-items: center;
					color: #818181;
					font-size: 12px;
					margin-top: 5px;
					margin-bottom: 10px;
					.rep_huif{
						cursor: pointer;
						display: flex;
						align-items: center;
						margin-left: 25px;
						img{
							width: 12px;
							height: 12px;
							margin-right: 5px;
						}
					}
				}
				.coment_pinl{
					background-color: #F6F6F6;
					padding: 0 15px 15px 15px;
					overflow: hidden;
					.coment_pinl_a{
						margin-top: 12px;
						padding-bottom: 17px;
						border-bottom: 1px solid #E2E2E2;
						.coment_pinl_b{
							font-size: 14px;
							color: #535353;
							img{
								width: 30px;
								height: 30px;
								border-radius: 50%;
								margin-right: 12px;
							}
						}
						.commen_pinl_c{
							margin-left: 42px;
							font-size: 14px;
							color: #999999;
							.rep_times{
								display: flex;
								align-items: center;
								font-size: 12px;
								.rep_huif{
									cursor: pointer;
									display: flex;
									align-items: center;
									margin-left: 25px;
									img{
										width: 12px;
										height: 12px;
										margin-right: 5px;
									}
								}
							}
						}
					}
					.commen_pinl_d{
						color: #00A3E0;
					}
					.activ_coms{
						border: none;
					}
				}
				.open{
					border-top: 1px solid #E2E2E2;
					cursor: pointer;
					display: flex;
					flex-flow: row;
					justify-content: center;
					margin: 20px 25px 0 70px;
					padding-top: 20px;
					box-sizing: border-box;
					color: #00A3E0;
					font-size: 16px;
				}
				::v-deep .publish{
					display: flex;
					flex-flow: row;
					justify-content: center;
					border-top: 1px solid #F7F7F7;
					padding-top: 10px;
					margin-top: 20px;
					box-sizing: border-box;
					color: #999999;
					font-size: 14px;
					div{
						cursor: pointer;
						height: 36px;
						border: 1px solid #E2E2E2;
						width: 554px;
						margin-right: 6px;
						padding-left: 13px;
						line-height: 34px;
					}
				}
			}
		}
		.heads{
			width: 46px;
			height: 46px;
			background-color: #fff;
			border-radius: 50%;
			position: fixed;
			border: 1px solid #E2E2E2;
			bottom: 200px;
			right: 457px;
			text-align: center;
			line-height: 50px;
		}
		::v-deep .dilogs{
			.el-dialog__header{
				background-color: #F8F9FB;
				button{
					display: none;
				}
			}
			.dialog-footer{
				display: inline-block;
				width: 100%;
				display: flex;
				flex-flow: row;
				justify-content: center;
				button:first-child{
					background-color: #97999B;
					color: #fff;
				}
			}
			.dilog_a{
				display: flex;
				margin-bottom: 25px;
				.dils_labe{
					width: 100px;
					text-align: right;
				}
			}
		}
		::v-deep .dilogsa{
			.el-dialog__header{
				padding: 0 0 0 20px;
				height: 50px;
				line-height: 50px;
				box-sizing: border-box;
				border-radius: 4px 4px 0px 0px;
				font-size: 16px;
				color: #535353;
				background-color: #F8F9FB;
				button{
					display: none;
				}
			}
			.el-dialog__body{
				padding: 22px 25px 35px 27px;
				box-sizing: border-box;
				font-size: 16px;
				color: #535353;
			}
			.el-dialog__footer{
				padding-top: 0;
			}
			.dialog-footer{
				display: inline-block;
				width: 100%;
				display: flex;
				flex-flow: row;
				justify-content: center;
				button{
					width: 100px;
					padding: 12px 0;
					text-align: center;
				}
				button:first-child{
					background-color: #97999B;
					color: #fff;
				}
			}
		}
	}
</style>
